//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import qs from 'qs'
import { distinctFilterBy } from '~/utils/array'
import { LANGUAGE_FLAG_IMGS } from '~/utils/runtimeConstants'
import { legalHubArticleListExcludedFields, legalHubArticleExcludedTypes } from '~/utils/constants'

const enabledLocales = new Set(['en-gb', 'sv-se', 'fi-fi', 'de-de'])

export default {
  data () {
    // Note: This is temporary logic to show articles only in locales that currently have new Storyblok legal hub in use. Remove this later when all legacy legal articles have been moved to SB
    const showArticles = enabledLocales.has(this.$country.current.code)

    return {
      showArticles,
      tabs: [
        { title: this.$t('templateSearch.legalTemplates'), key: 'templates' },
        { title: this.$t('templateSearch.legalArticles'), key: 'articles' }
      ],
      query: this.$store.state.layout.searchQuery || '',
      templates: [],
      templatesTotal: null,
      articles: [],
      articlesTotal: null,
      algolia: {},
    }
  },
  fetchKey: 'template-search',
  async fetch () {
    if (!this.$country.current.countryCode) { return }

    try {
      this.algolia = await this.$api.getAlgoliaSecuredApiKeys().then(r => r.data.data)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }

    await Promise.all([
      this.fetchTemplates('', true),
      this.fetchArticles(''),
    ])
  },
  computed: {
    searchOpen: {
      get () {
        return this.$store.state.layout.searchOpen
      },
      set (value) {
        this.$store.commit('layout/SET_SEARCH_OPEN', value)
      }
    },
    searchQuery () {
      return this.$store.state.layout.searchQuery
    },
    activeTab: {
      get () {
        return this.$store.state.layout.searchTab
      },
      set (value) {
        this.$store.commit('layout/SET_SEARCH_TAB', value)
      },
    },
  },
  watch: {
    searchQuery (q) {
      if (q) {
        this.query = q
      }
    },
    searchOpen (open) {
      if (open) {
        document.body.classList.add('prevent-scroll')

        this.$nextTick((_) => {
          this.$refs.searchInput.focus()
        })
      } else {
        document.body.classList.remove('prevent-scroll')
        this.$store.commit('layout/SET_SEARCH_QUERY', null)
        this.query = ''
        // Reset this too for consistency
        this.activeTab = 'templates'
      }
    },
    async query (query) {
      await Promise.all([
        this.fetchTemplates(query),
        this.fetchArticles(query),
      ])
    }
  },
  methods: {
    flag (l) {
      return LANGUAGE_FLAG_IMGS[l]
    },
    totalText (tab) {
      const total = tab === 'templates' ? this.templatesTotal : this.articlesTotal
      if (total === null) {
        return '...'
      }
      if (this.query === '') {
        return this.$t('templateSearch.nTotal', { num: total })
      }
      return this.$t('templateSearch.nResults', { num: total })
    },
    openSearch () {
      this.searchOpen = true
    },
    hideSearch () {
      this.searchOpen = false
    },
    handleClick (e) {
      // No auto-hide here
      if (this.query) {
        return
      }

      // Clicked outside the modal?
      if (this.$refs.modal && !this.$refs.modal.contains(e.target)) {
        this.hideSearch()
      }
    },
    async fetchTemplates (query) {
      try {
        const params = qs.stringify({
          query,
          hitsPerPage: 100,
          highlightPreTag: '<span class="tw-font-semibold">',
          highlightPostTag: '</span>',
        })

        const response = await this.$axios.post(`https://${this.algolia.app_id}-dsn.algolia.net/1/indexes/templates/query`, {
          params
        }, {
          headers: {
            'X-Algolia-API-Key': this.algolia.templates,
            'X-Algolia-Application-Id': this.algolia.app_id
          }
        })
        this.templates = response.data.hits.filter(distinctFilterBy(h => h.id))
        this.templatesTotal = response.data.nbHits
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
      }
    },
    async fetchArticles (query) {
      try {
        const legalHubBasePrefix = this.$url.articlesIndex().substring(1)

        let articles = null
        let totalArticles = 0
        // Only search from meili if we have a search term. Falling back to SB when there's no search term works better for static rendering.
        if (query) {
          const meiliRes = await this.$meili.search('legal_hub', {
            q: query,
            hitsPerPage: 100,
            page: 1,
            attributesToRetrieve: ['path', 'title', 'meta-title'],
          })

          articles = meiliRes.hits.map(doc => ({
            ...doc,
            slug: doc.path.split('/').pop(),
            title: doc.title || doc['meta-title'],
          }))
          totalArticles = meiliRes.totalHits
        } else {
          const sbParams = {
            is_startpage: 0,
            filter_query: { component: { not_in: legalHubArticleExcludedTypes } },
            excluding_fields: legalHubArticleListExcludedFields,
            per_page: 100,
            sort_by: 'content.meta.title:asc',
          }
          const sbRes = await this.$sb.list(legalHubBasePrefix, sbParams)

          articles = sbRes.data.stories.map(a => ({
            ...a,
            title: a.content.title || a.content.meta?.title,
          }))
          totalArticles = sbRes.headers.total
        }

        this.articles = articles
        this.articlesTotal = totalArticles
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
      }
    },
    showAskForTemplateModal () {
      this.$emit('show-ask-for-template')
      this.searchOpen = false
    }
  }
}
