import { render, staticRenderFns } from "./ResourceSearch.vue?vue&type=template&id=6af71a58&"
import script from "./ResourceSearch.vue?vue&type=script&lang=js&"
export * from "./ResourceSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconSearchMd: require('/builds/docue/website/components/Icon/IconSearchMd.vue').default,OutlineXIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/outline/src/components/XIcon.vue').default,DTIconButton: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTIconButton/DTIconButton.vue').default,DTButton: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTButton/DTButton.vue').default,IconLayoutAlt02: require('/builds/docue/website/components/Icon/IconLayoutAlt02.vue').default,OutlineChevronRightIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/outline/src/components/ChevronRightIcon.vue').default,IconScales01: require('/builds/docue/website/components/Icon/IconScales01.vue').default})
